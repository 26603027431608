import React, {useEffect, useState} from "react"
import {connect} from "react-redux"
import {getUsers} from "../../../../store/helpers";

const mapStateToProps = (state) => ({})

const mapDispatchToProps = ({})

const SearchInput$ = (props) => {

  const type = props.type
  const searchType = props.searchType
  const isActive = type === searchType
  const searchTypeHandler = props.searchTypeHandler
  const setUserId = props.setUserId
  const [user, setUser] = useState(null)
  const [userList, setUserList] = useState([])

  useEffect( () => {
    if (user === null) setUserId(null)
  }, [user] )   // eslint-disable-line

  const userHandler = (user) => {
    return () => {
      setUserList([])
      setUserId(user?.id)
      setUser(user)
    }
  }

  const resetUserHandler = () => {
    setUserId(null)
    searchTypeHandler(type, null)()
    setUser(null)
  }

  const inputClass = (type) => {
    return isActive ? 'adminInputEnabled' : ''
  }

  const inputHandler = async (e) => {
    const isPhone = searchType === 'phone'
    if (e.target.value.length === 0) {
      setUserList([])
      return
    }
    let list = await getUsers(e.target.value, isPhone)
    if (!isPhone) list = list[0]
    setUserList(list)
  }

  return (
    <>
      {
        user
          ?
          <div
            onClick={searchTypeHandler(type, user?.id)}
            className={`adminInput cursor-default d-flex ai-center jc-center mb-0 mt-3 ${inputClass(type)}`}
          >
            <p>{user.name}</p>
            <div title="Сбросить" className="crossIcon" onClick={resetUserHandler}>X</div>
          </div>

          :
          <div className="pos-rel">
            <input onChange={inputHandler} onClick={searchTypeHandler(type, user?.id)} type="text"
                   className={`adminInput mb-0 mt-3 ${inputClass(type)}`}/>
            {isActive &&
            <div className="adminSearchBlock w-100p">
              {userList && userList?.length === 0 &&
              <p>Введите {searchType === 'phone' ? 'номер' : 'имя'}</p>
              }
              {userList && userList.map(item => (
                <p className="adminSearchUser" key={item.id}
                   onClick={userHandler(item)}>{searchType === 'phone' ? `(${item.phone})` : ''} {item.name}</p>
              ))}
              {!userList && <p>Ничего не найдено</p>}
            </div>
            }
          </div>
      }
    </>
  )
}

const SearchInput = connect(mapStateToProps, mapDispatchToProps)(SearchInput$)

export default SearchInput