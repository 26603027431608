import React from "react"

const Loader = (props) => {
  return(
    <div className="loaderWrapper">
      <div className="loaderContainer">
        <div className="loaderInner loaderInnerOne" style={{borderBottom: `3px solid ${props.color}`}} />
        <div className="loaderInner loaderInnerTwo" style={{borderRight: `3px solid ${props.color}`}} />
        <div className="loaderInner loaderInnerThree" style={{borderTop: `3px solid ${props.color}`}} />
      </div>
    </div>
  )
}

export default Loader