import React, {useState} from "react"
import {connect} from "react-redux"
import SearchInput from "./SearchInput/SearchInput";
import { generateToken } from "../../../store/helpers";

const mapStateToProps = (state) => ({})

const mapDispatchToProps = ({})

const Token$ = (props) => {

  const [searchType, setSearchType] = useState('id')
  const [userId, setUserId] = useState(null)
  const [tokenList, setTokenList] = useState([])
  const [termOptions, setTermOption] = useState('d')
  const [termValue, setTermValue] = useState('7')

  const termValueHandler = (e) => {
    setTermValue(e.target.value)
  }

  const termOptionsHandler = (e) => {
    setTermOption(e.target.value)
  }

  const userIdHandler = (id) => {
    // console.log(id)
    setUserId(id)
  }

  const inputClass = (type) => {
    return type === searchType ? 'adminInputEnabled' : ''
  }

  const searchTypeHandler = (type, value) => {
    return () => {
      setUserId(value)
      setSearchType(type)
    }
  }

  const idInputHandler = (e) => {
    setSearchType('id')
    setUserId(e.target.value)
  }

  const generateHandler = async () => {
    let list = tokenList.slice()
    let term = termValue + termOptions
    let newToken = await generateToken(userId, term)
    console.log(newToken)
    list.push(newToken)
    setTokenList(list)
  }

  const copyHandler = (value) => {
    return () => {
      let textField = document.createElement('textarea')
      textField.innerText = value
      document.body.appendChild(textField)
      textField.select()
      document.execCommand('copy')
      textField.remove()
      alert('Скопировано в буфер обмена')
    }
  }


  return (
    <div className="d-flex fd-column h-100p ai-center">

      <div className="loginBlockTitle mt-20">Сгенерировать токен авторизации</div>
      {/*<p>{userId}</p>*/}
      <p className="mt-20">По ID</p>
      <input onChange={idInputHandler} onClick={idInputHandler} type="number"
             className={`adminInput mt-3 ${inputClass('id')}`}/>

      <p>По имени</p>
      <SearchInput
        type="name"
        searchTypeHandler={searchTypeHandler}
        searchType={searchType}
        setUserId={userIdHandler}
      />

      <p className="mt-20">По номеру телефона</p>
      <SearchInput
        type="phone"
        searchTypeHandler={searchTypeHandler}
        searchType={searchType}
        setUserId={userIdHandler}
      />

      <p className="mt-20">Срок действия</p>
      <div className="d-flex mt-3">
        <input value={termValue} onChange={termValueHandler} className="adminInput w-240px" type="number"/>
        <select value={termOptions} onChange={termOptionsHandler} className="adminInput w-240px ml-20">
          <option value="d">дней</option>
          <option value="h">часов</option>
          <option value="m">минут</option>
          <option value="s">секунд</option>
        </select>
      </div>

      <button
        onClick={generateHandler}
        disabled={ (userId === null || userId === undefined || userId === '' || termValue === '' ) }
        className="adminButton mt-20"
      >Сгенерировать</button>

      {tokenList.length > 0 &&
      <table className="tokenTable">
        <thead>
        <tr>
          <th>ID</th>
          <th>Номер телефона</th>
          <th>Имя</th>
          <th>Токен</th>
          <th>Действителен до</th>
        </tr>
        </thead>
        <tbody>
        {tokenList.map((item, index) => {
          if (item.error) return(
            <tr key={index} className="color-red">
              <td colSpan={5}>Error: {item.error?.message}</td>
            </tr>
          )
          return(
            <tr key={item.token}>
              <td>{item.id}</td>
              <td>{item.phone}</td>
              <td>{item.name}</td>
              <td
                title="Скопировать"
                className="copyToken tokenCell"
                onClick={copyHandler(item.token)}
              >{item.token}</td>
              <td>{item.expiresIn}</td>
            </tr>
          )
        })}
        </tbody>
      </table>
      }

    </div>
  )
}

const Token = connect(mapStateToProps, mapDispatchToProps)(Token$)

export default Token