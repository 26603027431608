import React, { useEffect, useState } from 'react'
import { connect } from "react-redux";
import { toggleSingleProject } from "../../../store/actions";
import { getSpecialRightsList, addSpecialRight } from "../../../store/helpers";
import ProjectsList from "../ProjectsList";

const mapStateToProps = (state) => ({
  projects: state.projects,
})

const mapDispatchToProps = ({
  toggleSingleProject
})

const Rights$ = (props) => {

  const [tab, setTab] = useState('add')
  const [list, setList] = useState(null)
  const [description, setDescription] = useState('')

  useEffect( () => {
    onLoad().then(null)
  }, [] )

  const onLoad = async () => {
    setList( await getSpecialRightsList() )
  }

  const descriptionHandler = (e) => {
    setDescription(e.target.value)
  }

  const addHandler = async () => {
    let currentId = props.projects.find( item => item.isChecked )?.id
    if (!currentId) {
      alert('Приложение не выбрано')
      return
    }
    if (!description) {
      alert('Отсутствует описание')
      return
    }
    let result = await addSpecialRight(currentId, description)
    console.log(result)
  }

  return(
    <div className="rightsContainer">
      <div className="d-flex">
        <div style={ tab === 'add' ? {opacity: '0.8'} : null }
             onClick={ () => setTab('add') }
             className="adminButton">Добавить</div>
        <div style={ tab === 'edit' ? {opacity: '0.8'} : null }
             onClick={ () => setTab('edit') }
             className="adminButton ml-20">Редактировать</div>
      </div>
      {
        tab === 'add'
        ?
        <div className="d-flex fd-column ai-center">
          <p>Описание специальных прав:</p>
          <input value={description} onChange={descriptionHandler} type="text" className="adminInput"/>
          <p>Приложение:</p>
          <ProjectsList isNoSpecials={true} isSingle={true} />
          <div onClick={addHandler} className="adminButton">Применить</div>

        </div>
        :
        <div>
          <div>
            <select>
              <option value="null"> </option>
              { list && list.map( item => (
                <option key={item.id} value={item.id}>{item.description}</option>
              ) ) }
            </select>

          </div>
        </div>
      }
    </div>
  )
}

const Rights = connect(mapStateToProps, mapDispatchToProps)(Rights$)

export default Rights