import React from 'react';
// import { render } from 'react-dom';
import {createRoot} from 'react-dom/client';
import Layout from "./components/Layout";
import store from "./store/store";
import {Provider} from 'react-redux'
import './styles/reset.css'
import './styles/fonts.css'
import './styles/loader.css'
import './styles/main.css'
// render(
//   <React.StrictMode>
//     <Provider store={store}>
//       <Layout />
//     </Provider>
//   </React.StrictMode>,
//   document.getElementById('root')
// );


const root = createRoot(document.getElementById('root'));
root.render(
  <Provider store={store}>
    <Layout/>
  </Provider>
)