import React, {useEffect} from 'react'
import { connect } from "react-redux";
import Reg from "./Reg/Reg";
import Rights from "./Rights/Rights";
import SpecialRights from "./SpecialRights/SpecialRights";
import Token from "./Token/Token";
// import Domain from "./Domain";
import { adminAuthCheck, getProjects } from "../../store/helpers";
import { setAdminWindow, setProjectsList, resetProjectsList, resetHashes } from "../../store/actions";
import { filterStyle } from "../../store/constants";

const mapStateToProps = (state) => ({
  adminWindow: state.adminWindow,
})

const mapDispatchToProps = ({
  setAdminWindow, setProjectsList, resetProjectsList, resetHashes
})

const Admin$ = (props) => {

  useEffect( () => {
    onLoad()
  }, [] ) // eslint-disable-line

  const onLoad = async () => {
    adminAuthCheck().then(null)
    props.setProjectsList( await getProjects() )
  }

  const windowHandler = (window) => {
    props.resetProjectsList()
    props.resetHashes()
    props.setAdminWindow(window)
  }

  return(
    <div className="adminWrapper">

      <div className="adminSideBar">
        <p className="adminSideBarTitle">Панель администратора</p>
        { props.count && <p className="adminSideBarParagraph">Всего записей: {props.count}</p> }
        <div onClick={ () => windowHandler('reg') }
             style={ props.adminWindow === 'reg' ? {filter: filterStyle} : null }
             className="adminSideBarButton adminSideBarButtonReg">Регистрация</div>
        <div onClick={ () => windowHandler('rights') }
             style={ props.adminWindow === 'rights' ? {filter: filterStyle} : null }
             className="adminSideBarButton adminSideBarButtonEdit">Редактирование</div>
        <div onClick={ () => windowHandler('token') }
             style={ props.adminWindow === 'token' ? {filter: filterStyle} : null }
             className="adminSideBarButton adminSideBarButtonKey">Токен авторизации</div>


        {/*<div onClick={ () => windowHandler('specialRights') }*/}
        {/*     style={ props.adminWindow === 'specialRights' ? {filter: filterStyle} : null }*/}
        {/*     className="adminSideBarButton adminSideBarButtonRights">С Права</div>*/}



        {/*<div onClick={ () => windowHandler('domains') }*/}
        {/*     style={ props.adminWindow === 'domains' ? {filter: filterStyle} : null }*/}
        {/*     className="adminSideBarButton adminSideBarButtonDomain">Домены</div>*/}
      </div>

      <div className="adminContent">
        { props.adminWindow === 'reg' && <Reg /> }
        { props.adminWindow === 'rights' && <Rights /> }
        { props.adminWindow === 'specialRights' && <SpecialRights /> }
        { props.adminWindow === 'token' && <Token /> }
        {/*{ props.adminWindow === 'domains' && <Domain /> }*/}
      </div>

    </div>
  )
}

const Admin = connect(mapStateToProps, mapDispatchToProps)(Admin$)

export default Admin