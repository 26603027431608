export const filterStyle = 'invert(43%) sepia(93%) saturate(1291%) hue-rotate(161deg) brightness(94%) contrast(102%)'
export const SET_ADMIN_WINDOW = 'SET_ADMIN_WINDOW'
export const SET_PROJECTS_LIST = 'SET_PROJECTS_LIST'
export const RESET_PROJECTS_LIST = 'RESET_PROJECTS_LIST'
export const TOGGLE_PROJECT = 'TOGGLE_PROJECT'
export const TOGGLE_SINGLE_PROJECT = 'TOGGLE_SINGLE_PROJECT'
export const SET_HASHES = 'SET_HASHES'
export const RESET_HASHES = 'RESET_HASHES'
export const PUSH_TO_HASHES = 'PUSH_TO_HASHES'
export const TOGGLE_SPECIAL_RIGHT = 'TOGGLE_SPECIAL_RIGHT'
export const SET_REG_HASH = 'SET_REG_HASH'






