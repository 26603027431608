import React, {useEffect, useState} from 'react'
import {getProjectsDocsList} from "../../store/helpers";
import Button from "./Button";

const Content = () => {

  const [projectsList, setProjectsList] = useState(null)
  const [user, setUser] = useState(null)
  const [currentTime, setCurrentTime] = useState(new Date());

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentTime(new Date());
    }, 1000);

    // Cleanup interval on component unmount
    return () => clearInterval(timer);
  }, []);

  useEffect(() => {
    setUserHandler().then(res => {
      getProjects(res).then(null)
    })
  }, [])  // eslint-disable-line

  const setUserHandler = () => {
    setUser({
      name: localStorage.getItem('currentUserName'),
      token: localStorage.getItem('currentUserToken')
    })
    return new Promise(resolve => {
      resolve(localStorage.getItem('currentUserToken'))
    })
  }

  const clearCredentials = () => {
    localStorage.removeItem('currentUserName')
    localStorage.removeItem('currentUserPhone')
    localStorage.removeItem('currentUserToken')
  }

  const getProjects = async (token) => {

    let options = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token,
      }
    }

    let list = await fetch(`${process.env.REACT_APP_USER_API}/projects`)
      .then(res => res.json())
    list = list[0]
    let rights = await fetch(`${process.env.REACT_APP_USER_API}/getRights`, options)
      .then(res => res.json())
    // let docs = await getProjectsDocsList()
    // console.log(docs)
    if (!rights[0]) {
      // localStorage.clear()
      clearCredentials()
    }
    rights = rights[0].rights
    list.map((item, index) => {
      item.isAllowed = !!+rights[index]
      return null
    })
    // console.log(list)
    setProjectsList(list)
  }

  const logOut = () => {
    // localStorage.clear()
    clearCredentials()
    window.location.reload(true)
  }

  const checkForLarge = () => {
    let vw = window.innerWidth
    let buttons = 0
    for (let i = 0; i < projectsList.length; i++) {
      buttons += projectsList[i].isAllowed ? 1 : 0
    }
    return vw >= 1920 && buttons >= 12
  }

  const formatDate = (date) => {
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-based
    const year = date.getFullYear();
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');

    return `${day}.${month}.${year} ${hours}:${minutes}:${seconds}`;
  };

  const copyHandler = () => {
    let value = localStorage.getItem('currentUserToken')
    let textField = document.createElement('textarea')
    textField.innerText = value
    document.body.appendChild(textField)
    textField.select()
    document.execCommand('copy')
    textField.remove()
  }

  return(
    <div className="globalWrapper mainWrapper">
      {projectsList &&
      <div className="mainContainer ">
        <div className="userBar ai-center">
          <div className="d-flex fd-column">
            <p onDoubleClick={copyHandler}>Вітаємо, {user.name}</p>
            <p className="time mt-3">⏰ {formatDate(currentTime)}</p>
          </div>
          <p onClick={logOut} className="logoutButton">Вийти з акаунту</p>
        </div>
        <div
          style={checkForLarge() ? {gridTemplateColumns: "repeat(4, 1fr)"} : null}
          className="buttonsContainer"
        >
          { projectsList.map( (item, index) => {
            if (item.isAllowed && item.flag_exists_front) return(
              <Button
                key={index}
                name={item.name}
                color={`#${item.color}`}
                title={item.title}
                description={item.description}
              />
            )
            return null
          })}
        </div>
      </div>
      }

    </div>
  )
}

export default Content