import React, { useState, useEffect } from "react"
import { connect } from "react-redux"
import { setRegHash } from "../../../../store/actions";

const mapStateToProps = (state) => ({
  regHash: state.regHash
})

const mapDispatchToProps = ({
  setRegHash
})

const RegModal$ = (props) => {

  const hash = props.regHash

  const checkHash = async () => {
    let result = await fetch(`${process.env.REACT_APP_USER_API}/auth/hash/get/${hash}`)
      .then(res => res.json())
    if (result.hash) setHashInfo(result)
    else setHashInfo(false)
  }

  useEffect( () => {
    checkHash().then(null)
  }, [] )   // eslint-disable-line

  const [warning, setWarning] = useState(' ')
  // const [isLoading, toggleLoading] = useState(false)
  const [isDone, setDone] = useState(false)
  const [hashInfo, setHashInfo] = useState(null)

  const [name, setName] = useState('')
  const [phone, setPhone] = useState('')
  const [secretForPass, setPassword] = useState('')
  const [password2, setPassword2] = useState('')
  const [idGamma, setIdGamma] = useState('')

  const closeHandler = () => {
    props.setRegHash(null)
  }

  // const nameInput = useRef(null)
  // const phoneInput = useRef(null)
  // const passwordInput = useRef(null)
  // const password2Input = useRef(null)

  const regHandler = async () => {
    let token = localStorage.getItem('currentUserToken')

    // let name = nameInput.current.value
    // let phone = phoneInput.current.value
    // let password = passwordInput.current.value
    // let password2 = password2Input.current.value

    if (!name) {
      setWarning('Введите имя')
      return null
    }
    if (phone.length !== 10) {
      setWarning('Введите номер телефона в формате 0991234567')
      return null
    }
    if (!secretForPass) {
      setWarning('Введите пароль')
      return null
    }
    if (!password2) {
      setWarning('Введите пароль повторно')
      return null
    }
    if (secretForPass !== password2) {
      setWarning('Пароли не совпадают')
      return null
    }
    if (hashInfo.flagRequiredGammaId) {
      if (!idGamma) {
        setWarning('Введите табельный номер (Gamma ID)')
        return null
      }
      if (isNaN(+idGamma)) {
        setWarning('Табельный номер должен содержать только цифры')
        return null
      }
    }
    setWarning('')

    // return null

    let body = {hash, phone, password: secretForPass, name}
    if (hashInfo.flagRequiredGammaId) body.gammaId = +idGamma
    let options = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token,
      },
      body: JSON.stringify(body)
    }
    let result = await fetch(`${process.env.REACT_APP_USER_API}/auth/registration`, options)
      .then( res => res.json() )
    if (result === true) setDone(true)
    else if (result?.error) setWarning(result?.error?.message)
  }

  const nameHandler = (e) => {
    setName(e.target.value)
  }

  const phoneHandler = (e) => {
    setPhone(e.target.value)
  }

  const passwordHandler = (e) => {
    setPassword(e.target.value)
  }

  const password2Handler = (e) => {
    setPassword2(e.target.value)
  }

  const idGammaHandler = (e) => {
    setIdGamma(e.target.value)
  }

  return(
    <div onMouseDown={closeHandler} className="regModalWrapper d-flex jc-center ai-center">
      <div onMouseDown={e => e.stopPropagation()} className="regModalContainer">

        { !isDone &&
        <div className="regContainer">
          <p className="regTitle">Заполните данные для нового пользователя</p>

          <form autoComplete="nope">
            <input className="regInput"
                   id="input-field-1"
                   name="data-input-field-1"
                   autoComplete="off"
                   value={name}
                   onChange={nameHandler}
                   type="text"
                   placeholder="Введите имя"/>

            <input className="regInput"
                   id="input-field-2"
                   name="data-input-field-2"
                   autoComplete="off"
                   value={phone}
                   onChange={phoneHandler}
                   type="text"
                   placeholder="Введите нoмер тeлeфoна"/>

            <input className="regInput regInputPassword"
                   inputMode="none"
                   id="input-field-3"
                   name="data-input-field-3"
                   autoComplete="new-password"
                   value={secretForPass}
                   onChange={passwordHandler}
                   type="text"
                   placeholder="Введите парoль"/>

            <input className="regInput regInputPassword"
                   inputMode="none"
                   id="input-field-4"
                   name="data-input-field-4"
                   autoComplete="new-password"
                   value={password2}
                   onChange={password2Handler}
                   type="text"
                   placeholder="Введите парoль повторно"/>

            { hashInfo?.flagRequiredGammaId &&
            <input className="regInput"
                   id="input-field-5"
                   name="data-input-field-5"
                   autoComplete="off"
                   value={idGamma}
                   onChange={idGammaHandler}
                   type="text"
                   placeholder="Введите табельный номер"/>
            }


          </form>

          <p className="regWarning">{warning}</p>
          <button className="regButton" onClick={regHandler}>Зарегистрировать</button>
        </div>
        }
        { isDone &&
        <div className="regContainer">
          <p className="regTitle">Пользователь успешно зарегистрирован</p>
          <button className="regButton" onClick={closeHandler}>Закрыть</button>
        </div>
        }


      </div>
    </div>
  )
}

const RegModal = connect(mapStateToProps, mapDispatchToProps)(RegModal$)

export default RegModal