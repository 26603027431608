import React, { useState } from 'react'
import { connect } from "react-redux";
import { toggleProject, setHashes, toggleSpecialRight, pushToHashes, setRegHash } from "../../../store/actions";
import { getHash, collectSpecialRights, collectRights } from "../../../store/helpers";
import ProjectsList from "../ProjectsList";
import RegModal from "./RegModal/RegModal";

const mapStateToProps = (state) => ({
  projects: state.projects,
  hashes: state.hashes,
  regHash: state.regHash
})

const mapDispatchToProps = ({
  toggleProject, setHashes, toggleSpecialRight, pushToHashes, setRegHash
})

const Reg$ = (props) => {

  const [isGammaId, toggleIsGammaId] = useState(false)

  const isGammaIdHandler = () => {
    toggleIsGammaId(!isGammaId)
  }

  const hashHandler = async () => {
    let special = collectSpecialRights(props.projects)
    let rights = collectRights(props.projects)
    props.pushToHashes( await getHash(rights, special, isGammaId) )
  }

  const regHandler = async () => {
    let special = collectSpecialRights(props.projects)
    let rights = collectRights(props.projects)
    let hashResponse = await getHash(rights, special, isGammaId)
    props.setRegHash( hashResponse )
  }

  return (
    <div className="rightsContainer">

      { props.regHash && <RegModal /> }

      {!props.projects && <p>ЗагрузОчка</p>}
      {props.projects &&
      <>
        <div className="loginBlockTitle mt-20">Регистрация пользователя</div>
        <ProjectsList />

        <div className="d-flex regFooter">

          <div className="linksContainer" />

          <div className="d-flex fd-column ai-center">
            <div onClick={isGammaIdHandler} className="gammaCheckbox mt-20">
              <input type="checkbox" checked={isGammaId} onChange={ () => void 0 }/>
              <p>ID Gamma</p>
            </div>
            <div className="d-flex">
              <div onClick={regHandler} className="adminButton mt-20">Зарегистрировать сейчас</div>
              <div onClick={hashHandler} className="adminButton mt-20 ml-20">Сгенерировать ссылку</div>
            </div>
          </div>
          <div className="linksContainer">
            {props.hashes.map(item => {
              return (
                <p className="link" key={item}>{`https://tmcu.lll.org.ua/reg/${item}`}</p>
              )
            })}
          </div>

        </div>




      </>
      }
    </div>
  )
}

const Reg = connect(mapStateToProps, mapDispatchToProps)(Reg$)

export default Reg