import React, { useEffect, useState } from 'react'
import {BrowserRouter as Router, Routes, Route} from "react-router-dom";
import Login from "./Login/Login";
import Content from "./Content/Content";
import Admin from "./Admin/Admin";
import Loader from "./Loader/Loader";
import NotFound from "./NotFound/NotFound";
import {getSearchParams} from "../store/helpers";

const Layout = () => {

  useEffect( () => {
    checkAuth().then(null)
  }, [] )

  const [isAuth, setAuth] = useState(null)
  const [theme, setTheme] = useState(localStorage.getItem('theme') || 'light')

  const changeTheme = () => {
    if (theme === 'light') {
      setTheme('dark')
      localStorage.setItem('theme', 'dark')
    } else {
      setTheme('light')
      localStorage.setItem('theme', 'light')
    }
  }

  const catchParams = () => {
    let {currentUserName, currentUserPhone, currentUserToken} = getSearchParams()
    if (currentUserToken) {
      if (currentUserName) localStorage.setItem('currentUserName', decodeURI(currentUserName))
      if (currentUserPhone) localStorage.setItem('currentUserPhone', currentUserPhone)
      if (currentUserToken) localStorage.setItem('currentUserToken', currentUserToken)
      window.location.href = window.location.origin
    }
  }

  const checkAuth = async () => {
    catchParams()
    const clearCredentials = () => {
      localStorage.removeItem('currentUserName')
      localStorage.removeItem('currentUserPhone')
      localStorage.removeItem('currentUserToken')
    }
    let userToken = localStorage.getItem('currentUserToken');
    let options = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': userToken,
      }
    }
    let result = await fetch(`${process.env.REACT_APP_USER_API}/checkRight/-1`, options)
      .then( res => {
        return res.status === 200
      } )
    setAuth(result)
    if (!result) clearCredentials()
  }

  return(
    <Router>
      <Routes>
          <Route path="/" element={
            <div className={theme}>
              <div onClick={changeTheme} className="themeButton"> </div>
              {
                isAuth === true
                  ?
                  <Content />
                  :
                  isAuth === false
                    ?
                    <Login />
                    :
                    <Loader />
              }
            </div>
          } />
          <Route path="/adminochka" element={ <Admin /> } />
          <Route element={ <NotFound /> } />
      </Routes>
    </Router>
  )
}

export default Layout;
