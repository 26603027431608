import React from "react"
import { connect } from "react-redux"
import { toggleProject, toggleSpecialRight, toggleSingleProject } from "../../store/actions";

const mapStateToProps = (state) => ({
  projects: state.projects,
  window: state.adminWindow
})

const mapDispatchToProps = ({
  toggleProject, toggleSpecialRight, toggleSingleProject
})

const ProjectsList$ = (props) => {

  const getButtonStyle = (item) => {
    if (!item.flag_exists_front) return {backgroundImage: `url("https://tmcu.lll.org.ua/_storage/api.png")`}
    return {backgroundImage: `url("https://apps.lll.org.ua/${item.name}/favicon.ico")`}
  }

  return(
    <div className="projectButtonsContainer mt-20">
      {
        props.projects.map((item, index) => {
          if (item.id !== 0) return (
            <div className="projectButton" key={item.id}>
              <div style={getButtonStyle(item)}
                   className={ (item.isChecked ? "projectButtonTitleActive" : "projectButtonTitleInactive") + " projectButtonTitle" }
                   onClick={ () => !props.isSingle ? props.toggleProject(index) : props.toggleSingleProject(index) }>{item.title}</div>
              { !props.isNoSpecials && item.special.map( (spec, sIndex) => {
                if (item.isChecked) return(
                  <div key={spec.id}
                       onClick={ () => props.toggleSpecialRight(index, sIndex) }
                       className="specialRights">
                    <input checked={spec.isChecked}
                           onChange={ () => void 0 }
                           type="checkbox"/>{spec.description}
                  </div>
                )
                return null
              } ) }
            </div>
          )
          return null
        })
      }
    </div>
  )
}

const ProjectsList = connect(mapStateToProps, mapDispatchToProps)(ProjectsList$)

export default ProjectsList