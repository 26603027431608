import React from "react";
import image from '../../img/admin.png'

const Button = (props) => {
  let icon
  if (props.name === 'adminochka') icon = `url("${image}")`
  else icon = `url("https://apps.lll.org.ua/${props.name}/favicon.ico")`
  const buttonsStyle = {
    backgroundColor: props.color,
    backgroundImage: icon
  }

  return(
    <a className="niceButtonAnchor" href={ `https://apps.lll.org.ua/${props.name}/` }>
      <div className="niceButton" style={{ borderColor: props.color }}>
        <div className="niceButtonIcon"
             style={buttonsStyle}> </div>
        <div className="niceButtonContent">
          <p className="niceButtonTitle">{props.title}</p>
          <p className="niceButtonDescription">{props.description}</p>
        </div>
      </div>
    </a>
  )
}

export default Button