import React from "react"
import './NotFound.css'
import video from './travolta.mp4'

const NotFound = () => {

  return(
    <div className="wrapper404">
      <div className="container404">
        <p className="code404">404</p>
        <p className="message404">Страница не найдена :(</p>
      </div>
      <video className="video404" src={video} width="500" height="482" muted loop autoPlay />
    </div>
  )
}

export default NotFound