import React, {useState} from 'react'
import { connect } from "react-redux";
import { toggleProject, setProjectsList, resetProjectsList } from "../../../store/actions";
import { getUserSpecialRights, collectRights, switchCurrentSpecialRights, setSpecialRights,
  removeSpecialRights, setNewRights, setNewPasswordAdmin } from "../../../store/helpers";
import ProjectsList from "../ProjectsList";

const mapStateToProps = (state) => ({
  projects: state.projects,
})

const mapDispatchToProps = ({
  toggleProject, setProjectsList, resetProjectsList
})

const Rights$ = (props) => {

  const [name, setName] = useState('')
  const [phone, setPhone] = useState('')
  const [userList, setUserList] = useState(null)
  const [user, setUser] = useState(null)

  const [userForPassword, setUserForPassword] = useState(null)
  const [password1, setPassword1] = useState('')
  const [password2, setPassword2] = useState('')
  const [passwordWarn, setPasswordWarn] = useState(null)
  const [passwordMessage, setPasswordMessage] = useState(null)

  const password1Handler = (e) => {
    setPasswordWarn(null)
    setPasswordMessage(null)
    setPassword1(e.target.value)
  }

  const password2Handler = (e) => {
    setPasswordWarn(null)
    setPasswordMessage(null)
    setPassword2(e.target.value)
  }

  const changePasswordHandler = async () => {
    if (password1.length < 6 || password2.length < 6) {
      setPasswordWarn('Пароль должен содержать минимум 6 символов')
      return
    }
    if (password1 !== password2) {
      setPasswordWarn('Пароли не совпадают')
      return
    }
    let result = await setNewPasswordAdmin(userForPassword.id, password1)
    if (!result) {
      setPasswordWarn('Ошибка смены пароля')
      return
    }
    setPasswordMessage('Пароль успешно изменён')
  }

  const nameHandler = (e) => {
    setName(e.target.value)
    setPhone('')
  }

  const phoneHandler = (e) => {
    setPhone(e.target.value)
    setName('')
  }

  const getUsers = async () => {
    if (name.trim().length === 0 && phone.trim().length === 0) {
      alert('Поле ввода пустое')
      return null
    }
    let token = localStorage.getItem('currentUserToken')
    let options = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token
      }
    }
    let path
    if (name) {
      path = `${process.env.REACT_APP_USER_API}/users/${name}`
    } else {
      path = `${process.env.REACT_APP_USER_API}/users/phone/${phone}`
    }
    let result = await fetch(path, options)
      .then( res => res.json() )
    if (name) result = result[0]
    setUserList(result)
  }

  const keyHandler = (e) => {
    if (e.which === 13) {
      getUsers()
    }
  }

  const setUserHandler = async (user) => {
    let list = props.projects.slice()
    list.map( (item, index) => {
      item.isChecked = !!+user.rights[index]
      return null
    } )
    let specials = await getUserSpecialRights(user.id)
    let updated = switchCurrentSpecialRights(list, specials)
    props.setProjectsList(updated)
    user.specials = specials
    setUser(user)
  }

  const setUserForPasswordHandler = (user) => {
    return () => {
      setUserForPassword(user)
    }
  }

  const setRights = async () => {
    let rights = collectRights(props.projects)
    let result = await setNewRights(user.id, rights)

    for (let i = 0; i < user.specials.length; i++) {
      await removeSpecialRights(user.specials[i].userHasRightId)
    }

    for (let i = 0; i < props.projects.length; i++) {
      for (let j = 0; j < props.projects[i].special.length; j++) {
        if (props.projects[i].special[j].isChecked) {
          await setSpecialRights(user.id, props.projects[i].special[j].id)
        }
      }
    }

    if (result) {
      alert('Права обновлены')
    } else {
      alert('Ошибочка')
    }
  }

  const resetUserForPasswordHandler = () => {
    setUserForPassword(null)
    setPassword1('')
    setPassword2('')
    setPasswordWarn(null)
  }

  const resetUserHandler = () => {
    setUser(null)
    props.resetProjectsList()
  }

  const resetUserListHandler = () => {
    resetUserHandler()
    setUserList(null)
  }

  const userListKeyBackHandler = (e) => {
    if (e.keyCode === 27 && userList && !user) {
      resetUserListHandler()
    }
    if (e.keyCode === 27 && userList && user) {
      resetUserHandler()
    }
  }

  return(
    <div className="rightsContainer" tabIndex={0} onKeyDown={ userListKeyBackHandler }>
      {
        userList && userForPassword
        ?
        <div className="regContainer mt-20">
          <div className="loginBlockTitle">Сменить пароль пользователю</div>
          <div className="loginBlockTitle">{userForPassword.name}</div>
          <input className="regInput regInputPassword mt-20"
                 value={password1}
                 onChange={password1Handler}
                 type="password"
                 placeholder="Введите парoль"/>
          <input className="regInput regInputPassword"
                 value={password2}
                 onChange={password2Handler}
                 type="password"
                 placeholder="Введите парoль повторно"/>
          {
            passwordWarn
            ?
              <p className="regWarning">{passwordWarn}</p>
              :
              <p className="regMessage">{passwordMessage}</p>
          }
          <div onClick={changePasswordHandler} className="adminButton">Сменить</div>
          <div onClick={resetUserForPasswordHandler} className="adminButton">Назад</div>
        </div>
        :
        userList && !user
        ?
        <>
          {userList.length === 0 &&
          <p className="mt-20">Не найдено пользователей по заданному параметру</p>
          }
          {userList.length !== 0 &&
          <div className="tableWrapper mt-20">
            <table className="table">
              <tbody>
              {userList.map( (item) => {
                return(
                  <tr key={item.id}>
                    <td className="nameCell">{item.name}</td>
                    <td className="phoneCell">{item.phone}</td>
                    <td className="cellHover" onClick={ () => setUserHandler(item) }>Права</td>
                    <td className="cellHover" onClick={setUserForPasswordHandler(item)}>Пароль</td>
                  </tr>
                )
              } )
              }
              </tbody>
            </table>
          </div>
          }

          <div onClick={resetUserListHandler} className="adminButton mt-20">Назад</div>
        </>
        :
        !userList && !user
        ?
        <>
          <div className="loginBlockTitle mt-20">Редактирование пользователя</div>
          <input value={name} onChange={nameHandler} onKeyDown={ e => keyHandler(e) } className="adminInput mt-20" type="text" placeholder="Введите имя пользователя"/>
          <p>или</p>
          <input value={phone} onChange={phoneHandler} onKeyDown={ e => keyHandler(e) } className="adminInput mt-20" type="text" placeholder="Введите номер пользователя"/>
          <div onClick={getUsers} className="adminButton">Найти</div>
        </>
        :
        <>
          <p className="adminTitle mt-20">{user.name}</p>
          <p className="adminParagraph">имеет доступ в:</p>
          <ProjectsList />
          <div className="d-flex mt-20">
            <div onClick={setRights} className="adminButton">Подтвердить</div>
            <div onClick={resetUserHandler} className="adminButton ml-20">Назад</div>
          </div>
        </>
      }
    </div>
  )
}

const Rights = connect(mapStateToProps, mapDispatchToProps)(Rights$)

export default Rights