const USER_API = process.env.REACT_APP_USER_API
const TOKEN = localStorage.getItem('currentUserToken')

const request = async (path, options, isBool) => {
  let response = await fetch(path, options)
    .then(res => {
      // console.log(res.status)
      // return res.json()
      if (isBool) return res.status === 200
      return res.json()
        .catch(e => {
          return e
        })
    })
    .catch(err => {
      console.log(err)
      return false
    })
  // console.log(path, '\n', response)
  return new Promise(resolve => resolve(response))
}

const generateOptions = (method, body) => {
  return {
    method: method,
    headers: {
      'Content-Type': 'application/json',
      'Authorization': TOKEN
    },
    body: JSON.stringify(body)
  }
}

export const getSearchParams = () => {
  let path = window.location.search
  if (!path) return {}
  let string = path.split('?')[1]
  let options = string.split('&')
  let result = {}
  for (let i = 0; i < options.length; i++) {
    let pairs = options[i].split('=')
    result[pairs[0]] = pairs[1]
  }
  return result
}

export const getSpecialRightsList = async () => {
  let options = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': TOKEN
    }
  }
  let response = await fetch(`${USER_API}/rights/special`, options)
    .then(res => {
      if (res.status === 200) return res.json()
      return res.json
    })
    .catch(err => {
      console.log(err)
      return false
    })

  return new Promise(resolve => resolve(response))
}

export const addSpecialRight = async (projectId, description) => {
  let body = {projectId, description}
  let options = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': TOKEN
    },
    body: JSON.stringify(body)
  }
  let response = await fetch(`${USER_API}/rights/special`, options)
    .then(res => {
      if (res.status === 200) return res.json()
      return res.json()
    })
    .catch(err => {
      console.log(err)
      return false
    })
  return new Promise(resolve => resolve(response))
}

export const collectRights = (list) => {
  let rights = ''
  for (let i = 0; i < 200; i++) {
    if (list[i]?.isChecked) {
      rights += '1'
    } else {
      rights += '0'
    }
  }
  return rights
}

export const collectSpecialRights = (list) => {
  let specialList = []
  for (let i = 0; i < list.length; i++) {
    for (let j = 0; j < list[i].special.length; j++) {
      if (list[i].special[j].isChecked) {
        specialList.push(list[i].special[j].id)
      }
    }
  }
  return specialList
}

export const switchCurrentSpecialRights = (list, specials) => {
  for (let i = 0; i < list.length; i++) {
    for (let j = 0; j < list[i].special.length; j++) {
      let singleSpecial = list[i].special[j]
      for (let k = 0; k < specials.length; k++) {
        if (singleSpecial.id === specials[k].rightId) {
          singleSpecial.isChecked = true
        }
      }
    }
  }
  return list
}

export const adminAuthCheck = async () => {
  let userToken = localStorage.getItem('currentUserToken');
  let options = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': userToken,
    }
  }
  if (userToken) {
    let result = await fetch(`${process.env.REACT_APP_USER_API}/checkRight/${process.env.REACT_APP_ID}`, options)
      .then(res => {
        if (res.status !== 200) return false
        return res.json()
      })
    if (!result) {
      localStorage.clear()
      window.location.href = 'https://apps.lll.org.ua/'
      return null
    }
    // setAuth(true)
  } else {
    localStorage.clear()
    window.location.href = 'https://apps.lll.org.ua/'
  }
}

export const getProjectsDocsList = async () => {
  let path = `https://tmcu.lll.org.ua/documentation_api/project/get/list`
  let options = generateOptions('GET')
  let response = await request(path, options)
  return new Promise(resolve => resolve(response))
}

export const getProjects = async () => {
  let projects = await fetch(`${process.env.REACT_APP_USER_API}/projects`)
    .then(res => res.json())
  projects = projects[0]
  projects.map((item, index) => {
    item.isChecked = false
    return null
  })
  let specialRights = await getSpecialRightsList()

  for (let i = 0; i < projects.length; i++) {
    let id = projects[i].id
    projects[i].special = []
    for (let j = 0; j < specialRights.length; j++) {
      if (i === 0) specialRights[j].isChecked = false
      if (specialRights[j].project_id === id) {
        projects[i].special.push(specialRights[j])
      }
    }
  }
  // console.table(specialRights)
  // console.log(projects)
  return new Promise(resolve => resolve(projects))
}

export const getHash = async (rights, special, isGamma) => {
  let params = {}
  if (special.length) params.specialRights = special.toString()
  params.flagRequiredGammaId = isGamma
  params.rights = rights
  // console.log(params)
  let options = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': TOKEN,
    },
    body: JSON.stringify(params)
  }
  let result = await fetch(`${process.env.REACT_APP_USER_API}/auth/hash/create`, options)
    .then(res => {
      return res.json()
        .catch(e => {
          console.log(e)
        })
      // if (res.status === 200) return res.json()
      // return false
    })
    .catch(err => {
      console.log(err)
      return false
    })
  return new Promise(resolve => resolve(result.hash))
}

export const getUserSpecialRights = async (id) => {
  let options = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': TOKEN
    }
  }
  let response = await fetch(`${USER_API}/rights/special/by/userId/${id}`, options)
    .then(res => {
      if (res.status === 200) return res.json()
      return []
    })
    .catch(err => {
      console.log(err)
      return []
    })
  return new Promise(resolve => resolve(response))
}

export const removeSpecialRights = async (idRight) => {
  let options = {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': TOKEN
    }
  }
  let response = await fetch(`${USER_API}/user/has/special/right/${idRight}/`, options)
    .then(res => {
      if (res.status === 200) return res.json()
      return false
    })
    .catch(err => {
      console.log(err)
      return false
    })
  // console.log(response)
  return new Promise(resolve => resolve(response))
}

export const setSpecialRights = async (idUser, idRight) => {
  let options = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': TOKEN
    },
    body: JSON.stringify({userId: idUser, specialRightId: idRight})
  }
  let response = await fetch(`${process.env.REACT_APP_USER_API}/user/has/special/right/`, options)
    .then(res => {
      if (res.status === 200) return res.json()
      return false
    })
    .catch(err => {
      console.log(err)
      return false
    })
  // console.log(response)
  return new Promise(resolve => resolve(response))
}

export const setNewRights = async (idUser, rights) => {
  let options = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': TOKEN
    },
    body: JSON.stringify({id: idUser, rights: rights})
  }
  let response = await fetch(`${process.env.REACT_APP_USER_API}/changeRights`, options)
    .then(res => {
      if (res.status === 200) return res.json()
      return false
    })
    .catch(err => {
      console.log(err)
      return false
    })
  return new Promise(resolve => resolve(response))
}

export const setNewPasswordAdmin = async (idUser, newPassword) => {
  let options = {
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': TOKEN
    },
    body: JSON.stringify({userId: idUser, password: newPassword})
  }
  let response = await fetch(`${process.env.REACT_APP_USER_API}/user/password`, options)
    .then(res => {
      return res.status === 200;
    })
    .catch(err => {
      console.log(err)
      return false
    })
  return new Promise(resolve => resolve(response))
}

export const getUsers = async (value, isByPhone) => {
  let pathName = `${USER_API}/users/${value}`
  let pathPhone = `${USER_API}/users/phone/${value}`
  let path = isByPhone ? pathPhone : pathName
  let options = generateOptions('GET')
  let response = await request(path, options)
  return new Promise(resolve => resolve(response))
}

export const generateToken = async (id, timeExp) => {
  let path = `${USER_API}/token/generate/`
  let options = generateOptions('POST', {id, timeExp})
  let response = await request(path, options)
  return new Promise(resolve => resolve(response))
}
